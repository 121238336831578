.main {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    height: 100vh;
    overflow-x: auto;
    overflow-y: auto;
    min-width: 250px;
}
.logo {
    width:75px;
    height:auto;
}
.logo-mobile {
    width:60px;
    height:auto;
}
.sidebar-list:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-list {
    border-left: 5px solid transparent;
    background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-list.active {
    border-left: 5px solid white;
    background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-sublist.active {
    background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-sublist.false a {
    opacity:.5;
    transition:.2s;
    display: block;
    line-height: 1.6;
}
.sidebar-sublist.false a:hover {
    opacity:1;
}

.sidebar-sublist.active a {
    display: block;
    line-height: 1.6;
}

.main::-webkit-scrollbar {
    width: 6px;
}
.main::-webkit-scrollbar-track {
    background: transparent;
}
.main::-webkit-scrollbar-thumb {
    background: var(--primary-color);
}
.main::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
}
