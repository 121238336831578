.company-card {
    background: #ffffff;
    box-shadow: 0px 5px 15px #f1f1f1;
    border-radius: 4px;
}
.data {
    background-color: var(--primary-color);
    border-radius: 4px;
    text-align: center;
}
/* .change.negative::before {
    font-family: "FontAwesome";
    content: "\f063";
    padding-right: 4px;
}
.change.positive::before {
    font-family: "FontAwesome";
    content: "\f062";
    padding-right: 4px;
} */
.divider {
    height: 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.25);
}
.link svg {
    font-size:18px;
    margin-left:0.25rem;
    position:relative;
    left:0;
}
.link a svg {
    transition:.2s;
}
.link a:hover svg {
    position:relative;
    left:3px;
}

.txt-danger svg path {
    fill: var(--danger-color);
}

.txt-secondary svg path {
    fill: var(--secondary-color);
}

.txt-danger svg, .txt-secondary svg {
position: relative;
top: -1px;
}
