:root {
    --primary-color: #00322f;
    --secondary-color: #02d2c5;
    --default-color: #ccc;
    --dark-color: #222222;
    --danger-color: #e53600;
}
.bgc-primary {
    background-color: var(--primary-color);
}
.bgc-secondary {
    background-color: var(--secondary-color);
}
.bgc-danger {
    background-color: var(--danger-color);
}
.bgc-dark {
    background-color: var(--dark-color);
}
.bgc-linear {
    background: linear-gradient(180deg, #222222 0%, #00a298 100%);
}
.bgc-light {
    background-color: #fafafa;
}
.bgc-white-tranparent {
    background-color: rgba(255, 255, 255, 0.4);
}
.txt-primary {
    color: var(--primary-color);
}
.txt-secondary {
    color: var(--secondary-color);
}
.txt-default {
    color: var(--default-color);
}
.txt-danger {
    color: var(--danger-color);
}
.link-primary {
    color: var(--primary-color) !important;
}
.link-primary:hover {
    color: var(--primary-color) !important;
}
.link-secondary {
    color: var(--secondary-color) !important;
}
.link-secondary:hover {
    color: var(--secondary-color) !important;
}
.link-white {
    color: #fff;
}
.link-white:hover {
    color: #fff;
}
