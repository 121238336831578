.app-container {
    min-height: 100vh;
}

@media (max-width: 767px) {
    .app-content {
        margin-top:6rem;
    }
}

@media (min-width: 768px) {
    .content-container {
        max-height: 100vh;
        overflow-y: scroll;
    }
}
