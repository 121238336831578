.MuiSvgIcon-root path {
    fill:#00A298;
}

.description-link {
    color: #00A298;
    font-weight:400;
}

.description-link:hover {
    text-decoration: none;
    color: #00A298;
}

.MuiPaper-root {
    box-shadow: 0px 5px 15px rgba(0,0,0,0.1) !important;
}

table th {
    font-size: 1rem !important;
    vertical-align: top !important;
    padding:1rem !important;
}

table td {
    font-size: 1rem !important;
    vertical-align: top !important;
    padding:1rem !important;
}

table a {
    color: var(--secondary-color);
}

.MuiTablePagination-root p, .MuiTablePagination-root .MuiSelect-select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
}

.MuiSvgIcon-root path {
}

.MuiTablePagination-root p {
    margin-bottom:0 !important;
}

.form-tools {
    margin-left: -0.25rem!important;
}