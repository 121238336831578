.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.zindex-5 {
    z-index: 5;
}
.full-screen {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.place-holder {
    padding: 2rem 0;
}
.dropdown-item:active{
    background-color: var(--primary-color);
}
.cursor-pointer{
    cursor: pointer;
}
@media (max-width: 768px) {
    .place-holder {
        padding: 4rem 0;
    }
}
