.hamburger {
    font-size:20px !important;
}

.dropdown-container {
    min-height: 100vh;
}
.dropdown-container ul {
    height: 80vh;
    overflow-y: scroll;
}

.sidebar-list div:hover {
    cursor: pointer;
}
