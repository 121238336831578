* {
    font-family: "Rubik";
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-900 {
    font-weight: 900;
}
.fs-10 {
    font-size: 10px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-38 {
    font-size: 38px;
}
.fs-80 {
    font-size: 80px;
}
.font-f {
    font-family: "Rubik";
}
.lh-2 {
    line-height: 2;
}
.break-all {
    word-break: break-all;
}
.break-word {
    word-break: break-word;
}
